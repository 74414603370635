"use client";
import { createSlice } from "@reduxjs/toolkit";
import { isValidDate } from "@/utils/functions.client";

const initialState = {
  prizes: [],
  price: {},
  salesEnd: "",
  drawDate: "",
  gameId: 0,
  drawID: "",
  subgameId: 0,
  gameGroupId: "",
};

export const grandDrawSlice = createSlice({
  name: "grandDraw",
  initialState,
  reducers: {
    setDrawID(state, action) {
      let drawId = parseInt(action?.payload) || 0;
      if (isNaN(drawId)) drawId = 0;
      state.drawID = drawId;
    },
    setGrandDraw(state, action) {
      const grandDrawInfo = action?.payload || {};

      if (Object.keys(grandDrawInfo)?.length === 5) {
        if (
          Array.isArray(grandDrawInfo?.prizes) &&
          grandDrawInfo?.prizes?.length > 0
        ) {
          state.prizes = grandDrawInfo.prizes;
        }

        if (
          grandDrawInfo?.price?.amount &&
          grandDrawInfo?.price?.currencyCode
        ) {
          state.price = grandDrawInfo.price;
        }

        if (isValidDate(grandDrawInfo?.salesEnd)) {
          state.salesEnd = grandDrawInfo.salesEnd;
        }

        if (isValidDate(grandDrawInfo?.drawDate)) {
          state.drawDate = grandDrawInfo.drawDate;
        }

        if (!isNaN(parseInt(grandDrawInfo?.gameId) || 0)) {
          state.gameId = grandDrawInfo.gameId;
        }
      }
    },
    setGrandDrawPrizes(state, action) {
      let prizes = action?.payload || [];
      if (!Array.isArray(prizes) && prizes?.length > 0) prizes = [];
      state.prizes = prizes;
    },
    setGrandDrawPrice(state, action) {
      let price = action?.payload || {};
      if (!price?.amount || !price?.currencyCode) price = {};
      state.price = price;
    },
    setGrandDrawSalesEnd(state, action) {
      let salesEnd = action.payload || "";
      if (!isValidDate(salesEnd)) salesEnd = "";
      state.salesEnd = salesEnd;
    },
    setGrandDrawDrawDate(state, action) {
      let drawDate = action.payload || "";
      if (!isValidDate(drawDate)) drawDate = "";
      state.drawDate = drawDate;
    },
    setGrandDrawGameId(state, action) {
      let gameId = parseInt(action?.payload) || 0;
      if (isNaN(gameId)) gameId = 0;
      state.gameId = gameId;
    },
    setSubGameGroupId(state, action) {
      state.subgameId = action.payload;
    },
  },
});

export default grandDrawSlice.reducer;

export const {
  setDrawID,
  setGrandDraw,
  setGrandDrawPrizes,
  setGrandDrawPrice,
  setGrandDrawSalesEnd,
  setGrandDrawDrawDate,
  setGrandDrawGameId,
  setSubGameGroupId,
  setGameGroupId,
} = grandDrawSlice.actions;

export const selectGrandDrawPrizes = (state) => state.grandDraw.prizes;
export const selectGrandDrawPrice = (state) => state.grandDraw.price;
export const selectGrandDrawSalesEnd = (state) => state.grandDraw.salesEnd;
export const selectGrandDrawDrawDate = (state) => state.grandDraw.drawDate;
export const selectGrandDrawGameId = (state) => state.grandDraw.gameId;
export const selectGrandDraw = (state) => state.grandDraw;
export const selectDrawID = (state) => state.grandDraw.drawID;
